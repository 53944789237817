import React, { useState } from 'react'
import Layout from '../../../components/layouts'
import Banner from '../../../components/features/Banner'
import SEO from '../../../components/common/SEO';
import ReactWOW from 'react-wow'
import FeatureCards from "../../../components/features/FeatureCards"

export const query = graphql`
  query Legislation {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
      }
    }
  }
`

export default ({ data }) => {
  const [toggler, setToggler] = useState(false)
  const lightboxProps = {
    toggler: toggler,
    setToggler: setToggler,
    sources: ["https://www.youtube.com/watch?v=xE2yXvWB038"],
  }

  const bannerProps = {
    title: 'Legislation',
    subtitle: `Aah, Legislation - a dry topic, and yet a vital part of your organization. Politicians work tirelessly over months and years to produce these riveting best sellers. Who are we kidding? They are repetitive, riddled with legalese and make for undeniably dull reading material. Have you ever heard the phrase, ‘You can’t make a silk purse from a sow’s ear?” Well, we definitely aren’t suggesting that our Legislation component is going to make the subject more captivating, but we can guarantee it will make your legislation organized, accessible and easier to keep updated.`,
    featureName: 'behaviourObservations',
    rightButtonText: 'Why Certificate Tracking Software?',
    rightButtonSrc: 'https://www.youtube.com/watch?v=xE2yXvWB038',
    mockupImg: "/features/legislation/legislation-banner.png"
  }

  const seo = {
    title: 'Workhub | Free Legislation Management Software Organize Update',
    description: 'Our free online Legislation management software makes your workplace legislation more accesible to workers & easier to keep updated and organized.',
    image: '/features/legislation/social-card.jpg',
    // pathname,
    // article
  }

  const featureIndex = [
    {
      title: "Choose from our library",
      para: "Our library contains legislation links from all around the world. Filter by continent, country, and state/province. ",
    },
    {
      title: "Easy to enable",
      para: "Enable any legislation related to your organization with a couple of clicks. ",
    },
    {
      title: "Report broken links ",
      para: "Easily report any broken links. If you require legislation that isn’t listed, send us a message & our team will add it in for you!",
    },
  ]

  return (
    <Layout {...lightboxProps}>
      <SEO {...seo} {...data.site} />
      <Banner {...bannerProps} {...lightboxProps} />

      <main className="feature-main">





        <section className="feature-section">
          <div className="container" >
            <div className="row switch-children">

              <div className="col-lg-6 feature-img">

                <ReactWOW animation="fadeInLeft" delay="0.9s">
                  <img
                    className="feature-img-right"
                    src="/features/legislation/legislation-cartoon.png"
                    alt="legislation cartoon of person trying to organize tons of books"
                  /></ReactWOW>

              </div>


              <div className="col-lg-6 feature-text">
                <h2>A long, long (not that long) time ago I can still remember…</h2>
                <p>It is so good to belong to a digital world. In years past having legislation available for employees meant having to keep the documents (or even books!) on-hand. Updating them with a revision or amendment was painful because it relied on your or someone else’s organizational dedication. If you had to look something up it sometimes meant rifling through hundreds of pages. No more! Our Legislation component makes these records & regulations available at the click of a button.</p>
              </div>
            </div>
          </div>
        </section>


        <section className="feature-section">
          <div className="container" >
            <div className="row">


              <div className="col-lg-6 feature-text">


                <h2>Across the seven seas.</h2>

                <p>Our Legislation feature contains links from around the world and likely already has a lot of the legislation that you will need. These come directly from government and regulatory sources online and you can select which ones to enable for your organization. To browse legislation filter by country, and then state or province.</p>

                <p>If you require legislation that isn’t listed, contact our support team and we will be happy to add it in for you! Plus, you can easily report broken or missing legislation to us with the click of a button. </p>

              </div>

              <div className="col-lg-6 feature-img">

                <ReactWOW animation="fadeInRight" delay="0.9s">

                  <img
                    className="feature-img-left"
                    src="/features/legislation/legislation-tablet.png"
                    alt="Admin view on tablet of legislation countries"
                  />

                </ReactWOW>

              </div>

            </div>
          </div>
        </section>



        <section className="feature-section">
          <div className="container" >
            <div className="row switch-children">

              <div className="col-lg-6 feature-img">

                <ReactWOW animation="fadeInLeft" delay="0.9s">

                  <img
                    className="feature-img-right"
                    src="/features/legislation/legislation-computer.png"
                    alt="Admin view on computer of Legislation view"
                  />

                </ReactWOW>

              </div>


              <div className="col-lg-6 feature-text">

                <h2>No one here by the name of Black Beard.</h2>

                <p>You’re not the captain of a pirate ship and you already know that you’re following all the laws and legislature. But do your employees? Having legislation available for your workers is an invaluable way to educate, create transparency and show that you’re dedicated to providing a safe and fair workplace. </p>



              </div>
            </div>
          </div>
        </section>



        <section className="feature-section">
          <div className="container">
            <div className="row">

              <div className="col-lg-6 feature-text">

                <h2>Give them a compass to guide the way.</h2>

                <p>The legislation you enable can be easily accessed from the Worker dashboard and is filtered by state, province or country. Anytime that Jimbo needs a refresh on the Transportation of Dangerous Goods Act it will be available to him, via any computer or mobile device. Does Kerry have a question about Workers Compensation? She knows where to find it.</p>

              </div>

              <div className="col-lg-6 feature-img">

                <ReactWOW animation="fadeInLeft" delay="0.9s">

                  <img
                    className="feature-img-left"
                    src="/features/legislation/legislation-mobile.png"
                    alt="Worker view of legislation on mobile"
                  />

                </ReactWOW>

              </div>


            </div>
          </div>
        </section>


        {/* FEATURE OVERVIEW */}

        <section className="feature-overview-container">
          <h2>Overview</h2>

          <div className="feature-overview-grid">
            {featureIndex.map(item => (
              <FeatureCards title={item.title} para={item.para} />
            ))}
          </div>
        </section>

      </main>

    </Layout>
  )
}